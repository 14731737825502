export class BrowserCompatibilityExtensions {
	public static addEvent(el: any, eventName: string, eventHandler: any) {
		if (el.addEventListener) { // W3C DOM
			el.addEventListener(eventName, eventHandler, false);
		} else if (el.attachEvent) { // IE DOM
			el.attachEvent("on" + eventName, eventHandler);
		} else {
			el[eventName] = eventHandler;
		}
	}

	public static removeEvent(el: any, eventName: string, eventHandler: any) {
		if (el.removeEventListener) { // W3C DOM
			el.removeEventListener(eventName, eventHandler);
		} else if (el.detachEvent) { // IE DOM
			el.detachEvent("on" + eventName, eventHandler);
		} else {
			el[eventName] = undefined;
		}
	}
}
