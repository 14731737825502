import { Injectable } from "@angular/core";
import { Observable } from "rxjs";

import { AppSettings } from "../settings/app.settings";
import { HttpClientService } from "../utilities/http.client";
import { IIpsReportingVariable } from "../models/ips-reporting-variable.model";
import { IDictionary } from "./../interfaces/dictionary.interface";

@Injectable()
export class IpsService {
    constructor(
	   private appSettings: AppSettings,
	   private http: HttpClientService
    ) { }

    private urls: IDictionary<any> = {
	   "getIpsReportingVariables": this.appSettings.IPSApiPath + "IpsVariables/{subsampleId}",
    };

    getIpsReportingVariables = (subsampleId: number): Observable<IIpsReportingVariable[]> =>
	   this.http.getSubscription(this.urls["getIpsReportingVariables"], { "subsampleId": subsampleId || 0 });
}
