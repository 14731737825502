import { Injectable } from "@angular/core";
import { Observable } from "rxjs";

import { HttpClientService } from "../utilities/http.client";
import { ISurveyLevelQuotaModel } from "../models/survey-level-quota.model";
import { AppSettings } from "../settings/app.settings";
import { IQuotaExpressionModel } from "../models/quota-expression.model";
import { QuotaSourceEnum } from "../enums/quota-source.enum";
import { IQuotaRowModel } from "../models/quota-row.model";

@Injectable()
export class QuotaService {

	constructor(
		private appSettings: AppSettings,
        private http: HttpClientService
	) { }

	private urls = {
		getSurveyLevelQuotas: this.appSettings.AdminApiPath + "Subsample/{subsampleId}/SurveyLevelQuotas",
		saveSurveyLevelQuotas: this.appSettings.AdminApiPath + "Subsample/{subsampleId}/SurveyLevelQuotas",
		quotaSyncQueueInsert: `${this.appSettings.AdminApiPath}Quotas/QuotaSyncQueueInsert`,
		getDimensionsQuotaImport: `${this.appSettings.AdminApiPath}Quotas/DimensionsQuotaImportSelect`,
    dimensionsQuotaImport: `${this.appSettings.AdminApiPath}Quotas/DimensionsQuotaImport/{surveyId}`,
    getAllQuotas: this.appSettings.AdminApiPath + "Subsample/{subsampleId}/AllQuotas"
	};

	getSurveyLevelQuotas = (subsampleId: number): Observable<ISurveyLevelQuotaModel[]> => this.http.getSubscription(this.urls.getSurveyLevelQuotas, { subsampleId: subsampleId });

	saveSurveyLevelQuotas = (subsampleId: number, quotas: ISurveyLevelQuotaModel[], source: QuotaSourceEnum): Observable<number> =>
		this.http.postSubscription(this.urls.saveSurveyLevelQuotas, { "subsampleId": subsampleId || 0, "source": source || QuotaSourceEnum.Unknown }, quotas);

	quotaSyncQueueInsert = (eventId: number): Observable<boolean> =>
		this.http.putSubscription(this.urls.quotaSyncQueueInsert, { eventId: eventId });

	getDimensionsQuotaImport = (eventId: number): Observable<IQuotaExpressionModel[]> =>
		this.http.getSubscription(this.urls.getDimensionsQuotaImport, { eventId: eventId });

	dimensionsQuotaImport = (surveyId: number, quotaIdList: Array<number>): Observable<boolean> =>
    this.http.putSubscription(this.urls.dimensionsQuotaImport, { surveyId: surveyId }, quotaIdList);

  getAllQuotas = (subsampleId: number): Observable<IQuotaRowModel[]> =>
    this.http.getSubscription(this.urls.getAllQuotas, { subsampleId: subsampleId });
}
