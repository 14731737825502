export class SubsampleConstants {
  static QuotaOnCompletes: string = 'On Completes';
  static QuotaOnTargetClicks: string = 'On TargetClicks';
  static SaveQuotaMode: string = 'Save Quota Mode';
  static EditQuotaMode: string = 'Edit Quota Mode';
  static Cancel: string = 'Cancel';
  static Id: string = 'ID: ';
  static CreatedOn: string = 'Created On: ';
  static EmailDeliveryTime: string = 'Email delivery Time';
  static EmailDeliveryTimeValidationError: string = 'The date cannot be set in the past';
  static GoBack: string = 'Go back';
  static MailoutDetails: string = 'Mailout Details';
  static MailoutName: string = 'Mailout Name';
  static NewMailout: string = 'New Mailout'
  static MailoutNameValidationError: string = 'The name is required';
  static MailoutNameSpecialCharactersValidationError: string = 'The name cannot contain those characters';
  static CreateNewMailout: string = 'Create a New Mailout';
  static Status: string = 'Status';
  static Items: string = 'Items';
  static Action: string = 'Action';
  static EmailSpecificRespondents: string = 'Email Specific Respondents';
  static EditSampleQuotas: string = 'Edit Sample Quotas';
}
