import { AutoIncrementRecurrenceEnum } from "../enums/auto-increment-recurrence.enum";

export interface IAutoIncrementScheduleModel {
	interviews: number;
	recurrenceAmount: number;
	recurrence: AutoIncrementRecurrenceEnum;
	startTime: Date;
	endTime?: Date;
}

export class AutoIncrementScheduleModel implements IAutoIncrementScheduleModel {
	interviews: number = 0;
	recurrenceAmount: number = 0;
	recurrence: AutoIncrementRecurrenceEnum = AutoIncrementRecurrenceEnum.Hours;
	startTime: Date; // = new Date();
	endTime ?: Date; // = new Date();
}