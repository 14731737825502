import { Injectable } from "@angular/core";
import { Observable } from "rxjs";
import { IMonthlyBillingReportModel } from "../models/monthly-billing-report.model";
import { ISupplierInfoModel } from "../models/supplier-info.model";
import { AppSettings } from "../settings/app.settings";
import { HttpClientService } from "../utilities/http.client";
import { IDictionary } from "./../interfaces/dictionary.interface";
import { IUploadResponse } from "./../interfaces/IUploadResponse";


@Injectable()
export class AdminService {
  private urls: IDictionary<string> = {
    "uploadTemplates": this.appSettings.AdminApiPath + "/UploadTemplates",
    "getSupplierList": this.appSettings.AdminApiPath + "/MonthlyBilling/SupplierList",
    "downloadMonthlyBillingReport": this.appSettings.AdminApiPath + "/MonthlyBilling/DownloadReport",
    "saveDigitalFingerPrintOption": this.appSettings.AdminApiPath + "/DigitalFingerPrint",
    "getDigitalFingerPrintOption": this.appSettings.AdminApiPath + "/DigitalFingerPrint",
    "monthlyBillingReportExists": this.appSettings.AdminApiPath + "/MonthlyBilling/ReportExists",
    "respondentIdsConversions": this.appSettings.AdminApiPath + "respondentIds-conversion",
    "getNextProcessingListTimeStamp": this.appSettings.AdminApiPath + "/NextProcessingListTimeStamp",
    "respondentIdsDecryption": this.appSettings.AdminApiPath + "respondentIds-decryption",
    "respondentIdsEncryption": this.appSettings.AdminApiPath + "respondentIds-encryption",
    "startCheckingEditableSettings": this.appSettings.AdminApiPath + "start-checking-editable-settings",
    "getMailoutFile": this.appSettings.AdminApiPath + "GetMailoutFile",
    "downloadDEMReport": this.appSettings.AdminApiPath + "/DEMReport/DownloadReport",
    "getPastXHoursForDemReport": this.appSettings.AdminApiPath + "getPastXHoursForDemReport",
    "getDefaultThresholdForLOI": this.appSettings.AdminApiPath + "getDefaultThresholdForLOI",
    "getEnablePendingAtDailyTargetCompletion": this.appSettings.AdminApiPath + "getEnablePendingAtDailyTargetCompletion"
  };

  constructor(
    private appSettings: AppSettings,
    private http: HttpClientService
  ) { }

  uploadTemplate = (formData: FormData): Observable<IUploadResponse> =>
    this.http.postFile(this.urls["uploadTemplates"], formData);

  getSupplierList = (): Observable<ISupplierInfoModel[]> =>
    this.http.getSubscription(this.urls["getSupplierList"], {});

  downloadMonthlyBillingReport = (monthlyBillingReport: IMonthlyBillingReportModel): Observable<any> =>
    this.http.postFileSubscription(this.urls["downloadMonthlyBillingReport"], {}, monthlyBillingReport);

  saveDigitalFingerPrintOption = (option: number): Observable<boolean> =>
    this.http.putSubscription(this.urls["saveDigitalFingerPrintOption"], { "option": option });

  getDigitalFingerPrintOption = (): Observable<number> =>
    this.http.getSubscription(this.urls["getDigitalFingerPrintOption"], {});

  monthlyBillingReportExists = (monthlyBillingReport: IMonthlyBillingReportModel): Observable<boolean> =>
    this.http.postSubscription(this.urls["monthlyBillingReportExists"], {}, monthlyBillingReport);

  convertRespondentIds = (formData: FormData, conversionDirection: string): Observable<Blob> =>
    this.http.postFileSubscriptionWithDownload(this.urls["respondentIdsConversions"], formData, { "conversionDirection": conversionDirection });

  decryptRespondentIds = (formData: FormData): Observable<Blob> =>
    this.http.postFileSubscriptionWithDownload(this.urls["respondentIdsDecryption"], formData);

  encryptRespondentIds = (formData: FormData): Observable<Blob> =>
    this.http.postFileSubscriptionWithDownload(this.urls["respondentIdsEncryption"], formData);

  getNextProcessingListTimeStamp = (): Observable<Date> =>
    this.http.getSubscription(this.urls["getNextProcessingListTimeStamp"], {});

  startCheckingEditableSettings = (): Observable<boolean> =>
    this.http.getSubscription(this.urls["startCheckingEditableSettings"], {});

  getMailoutFile = (mailoutId: number): Observable<any> =>
    this.http.postFileSubscription(this.urls["getMailoutFile"], {}, mailoutId);

  downloadDEMReport = (hours: number): Observable<any> =>
    this.http.postFileSubscription(this.urls["downloadDEMReport"], {}, hours);

  getPastXHoursForDemReport(): Observable<number> {
    return this.http.getSubscription(this.urls["getPastXHoursForDemReport"]);
  }

  getDefaultThresholdForLOI(): Observable<number> {
    return this.http.getSubscription(this.urls["getDefaultThresholdForLOI"]);
  }

  getEnablePendingAtDailyTargetCompletion(): Observable<boolean> {
    return this.http.getSubscription(this.urls["getEnablePendingAtDailyTargetCompletion"]);
  }

}
