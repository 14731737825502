import { Location } from "../utilities/location";
import { BrowserCompatibilityExtensions } from "../utilities/browser-compatibility.extensions";

export class DetourExtensions {

    public popEsenderWin(win: string) {
        if (!win) { return; }

        let finish = (warning: boolean, error: string) => {
            if (warning) {
                alert(`There was a problem opening a new window ( ${error} )!. 
One reason may be that your browser is blocking popus from ${Location.host}. Please enable popups and try again.`);
            }
        };

        let tab: any;
        try {
            tab = window.open(win, "_blank");
        } catch (ex1) {
            finish(true, ex1);
            return;
        }
        if (!tab) {
            finish(true, "unknown");
            return;
        }

        try {
            let loadEvent = BrowserCompatibilityExtensions.addEvent(tab, "load", () => {
                BrowserCompatibilityExtensions.removeEvent(tab, "load", loadEvent);
                finish(false, "");
            });
        } catch (ex2) {
            finish(true, ex2);
            return;
        }

        finish(false, "");
    }

}