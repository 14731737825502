import { Injectable } from "@angular/core";
import { Observable } from "rxjs";
import { AppSettings } from "../settings/app.settings";
import { HttpClientService } from "../utilities/http.client";
import { DateRangeTypeEnum } from "../enums/date-range-type.enum";
import { IQuotaFieldMonitoringTableInfoModel } from "../models/quota-field-monitoring-table-info.model";
import { ISubsampleFieldMonitoringInfoModel, SubsampleSampleFunnelCountsModel } from "../models/subsample-field-monitoring-info.model";
import { IInternalSampleQuotaTableInfoModel } from "../models/internal-sample-quota-table-info.model";
import { IInternalSampleStatus } from "../models/internal-sample-status.model";

@Injectable()
export class FieldMonitoringService {
  constructor(
    private appSettings: AppSettings,
    private http: HttpClientService
  ) { }

  private urls = {
    getQuotaFieldMonitoring: this.appSettings.AdminApiPath + "FieldMonitoring/Quota/{subsampleId}",
    getSubsampleFieldMonitoring: this.appSettings.AdminApiPath + "FieldMonitoring/Subsample",
    getInternalSampleFieldMonitoring: this.appSettings.AdminApiPath + "FieldMonitoring/InternalSample/{subsampleId}",
    getInternalSampleStatus: this.appSettings.AdminApiPath + "FieldMonitoring/InternalSampleStatus/{subsampleId}",
    getSampleFunnelCounts: this.appSettings.AdminApiPath + "FieldMonitoring/Funnel/{subsampleId}"
  };

  getQuotaFieldMonitoring(
    subsampleId: number,
    supplierId?: number,
    targeted?: number,
    dateRangeType?: DateRangeTypeEnum,
    isSupplierGroup?: boolean
  ): Observable<IQuotaFieldMonitoringTableInfoModel[]> {
    return this.http.getSubscription(
      this.urls.getQuotaFieldMonitoring,
      {
        "subsampleId": subsampleId || -1,
        "supplierId": !isSupplierGroup ? supplierId || 0 : -1,
        "supplierGroupId": isSupplierGroup ? supplierId || 0 : -1,
        "targeted": targeted || 0,
        "dateRangeType": dateRangeType || 1
      }
    );
  }

  getSubsampleFieldMonitoring(
    eventId?: number,
    subsampleId?: number,
    supplierId?: number,
    targeted?: number,
    dateRangeType: DateRangeTypeEnum = DateRangeTypeEnum.FromTheStart,
    isSupplierGroup?: boolean,
    getSupplierGroups: boolean = false
  ): Observable<ISubsampleFieldMonitoringInfoModel[]> {
    return this.http.getSubscription(
      this.urls.getSubsampleFieldMonitoring,
      {
        "eventId": eventId || -1,
        "subsampleId": subsampleId || -1,
        "supplierId": !isSupplierGroup ? supplierId || 0 : -1,
        "supplierGroupId": isSupplierGroup ? supplierId || 0 : -1,
        "targeted": targeted || 0,
        "dateRangeType": dateRangeType || 1,
        "getSupplierGroups": getSupplierGroups
      }
    );
  }

  getInternalSampleFieldMonitoring(
    subsampleId: number,
    percentage?: number,
    subsampleSIFId?: number,
    readOnlyMode?: boolean
  ): Observable<IInternalSampleQuotaTableInfoModel[]> {
    return this.http.getSubscription(
      this.urls.getInternalSampleFieldMonitoring,
      {
        "subsampleId": subsampleId,
        "percentage": percentage || 0,
        "subsampleSIFId": subsampleSIFId || -1,
        "readOnlyMode": readOnlyMode || false
      }
    );
  }

  getInternalSampleStatus(subsampleId: number): Promise<IInternalSampleStatus> {
    return this.http.get(this.urls["getInternalSampleStatus"], { "subsampleId": subsampleId });
  }

  getSampleFunnelCounts(subsampleId?: number, supplierId?: number, targeted?: number, dateRangeType: DateRangeTypeEnum = DateRangeTypeEnum.FromTheStart, isSupplierGroup?: boolean,): Observable<SubsampleSampleFunnelCountsModel> {
    return this.http.getSubscription(
      this.urls.getSampleFunnelCounts,
      {
        "subsampleId": subsampleId || -1,
        "supplierId": !isSupplierGroup ? supplierId || 0 : -1,
        "supplierGroupId": isSupplierGroup ? supplierId || 0 : -1,
        "targeted": targeted || -1,
        "dateRangeType": dateRangeType || 1
      }
    );
  }

}
