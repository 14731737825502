export enum SampleRequestStatusEnum {
  Pending = 0,
  ReadyForExtraction = 1,
  ScheduledForExtraction = 2,
  Extracting = 3,
  WaitingForConfirmation = 4,
  ExtractionConfirmed = 5,
  ReadyForMailout = 6,
  ScheduledForMailout = 7,
  Delivered = 8,
  Canceled = 9,
  Error = 10,
  SendingToESender = 11,
}

export enum SubsampleMailoutStatusEnum {
  Pending = "Pending",
  Processing = "Processing",
  SentToP1 = "Sent to P1",
  Canceled = "Canceled"
}

export const MailoutStatuses: Map<number, string> = new Map<number, string>([
  [0, SubsampleMailoutStatusEnum.Pending],
  [1, SubsampleMailoutStatusEnum.Processing],
  [2, SubsampleMailoutStatusEnum.SentToP1],
  [3, SubsampleMailoutStatusEnum.Canceled]
]);
