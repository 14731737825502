import { ArrayExtensions } from "../utilities/array.extensions";
import { IEventFieldMonitoringInfoModel } from "./event-field-monitoring-info.model";
import { ISubsampleSupplierModel } from "./subsample-supplier.model";
import { ISubsampleSupplierGroupModel } from "./subsample-supplier-group.model";
import { ISubsampleSupplierCountsModel } from "./subsample-supplier-counts.model";

export interface ISubsampleFieldMonitoringInfoModel extends IEventFieldMonitoringInfoModel {
	complete?: number;
	completeRate?: number;
	cpi?: number;
	loi?: number;
	incidence?: number;
	abandonRate?: number;
	conversionRate?: number;
	estimatedAbandonRate?: number;
	estimatedCompletes?: number;
	estimatedConversionRate?: number;
	estimatedCpi?: number;
	estimatedIncidence?: number;
	estimatedLoi?: number;
	fieldStartDate?: Date;
	fieldEndDate?: Date;
	lastUpdatedDate?: Date;
	nextUpdateDate?: Date;
	suppliers?: ISubsampleSupplierModel[];
	supplierGroups?: ISubsampleSupplierGroupModel[];
  suppliersCounts?: ISubsampleSupplierCountsModel[];
    timePassedSinceLastUpdate?: Date;
    timeLeftUntilNextUpdate?: number;
}

export class SubsampleFieldMonitoringInfoModel implements ISubsampleFieldMonitoringInfoModel {
	complete = 0;
	completeRate = 0;
	cpi = 0;
	loi = 0;
	incidence = 0;
	abandonRate = 0;
	conversionRate = 0;
	estimatedAbandonRate = 0;
	estimatedCompletes = 0;
	estimatedConversionRate = 0;
	estimatedCpi = 0;
	estimatedIncidence = 0;
	estimatedLoi = 0;
	fieldStartDate = undefined;
	fieldEndDate = undefined;
	lastUpdatedDate = new Date();
	nextUpdateDate = new Date();
	suppliers = [];
	suppliersCounts = [];

	constructor(model?: ISubsampleFieldMonitoringInfoModel) {
		ArrayExtensions.deepCopy.apply(this, model);
	}
}

export class PrescreenerCountsModel {
  clicks?: number = 0;
  abandons?: number = 0;
  sniffouts?: number = 0;
  screenouts?: number = 0;
  quotafulls?: number = 0;
}

export class ClientSurveyCountsModel {
  surveyEntries?: number = 0;
  sniffouts?: number = 0;
  screenouts?: number = 0;
  iisScreenouts?: number = 0;
  quotafulls?: number = 0;
  iisQuotafulls?: number = 0;
  abandons?: number = 0;
  potentiallyUnengaged?: number = 0;
  wrongCompletes?: number = 0;
  completes?: number = 0;
}
export class SubsampleSampleFunnelCountsModel {
  prescreenerCounts?: PrescreenerCountsModel = new PrescreenerCountsModel();
  clientSurveyCounts?: ClientSurveyCountsModel = new ClientSurveyCountsModel();
  constructor(model?: SubsampleSampleFunnelCountsModel) {
    ArrayExtensions.deepCopy.apply(this, model);
  }
}
