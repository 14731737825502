import { Injectable } from "@angular/core";

import { AppSettings } from "../settings/app.settings";
import { HttpClientService } from "../utilities/http.client";
import { IDictionary } from "./../interfaces/dictionary.interface";
import { IIncentiveModel } from "./../models/incentive.model";
import { Observable } from "rxjs";
import { IRespondentsSelected } from "./../models/respondents-selected.model";
import { IDigestSummary } from "./../models/digest-summary";
import { IProcessingItem } from "../models/processing-item.model";

@Injectable()
export class PanelOneService {
	constructor(
		private appSettings: AppSettings,
		private http: HttpClientService
	) { }

  private urls: IDictionary<any> = {
    "incentive": this.appSettings.AdminApiPath + "Subsample/PanelOneIncentive/{subsampleId}",
    "respondentsSelected": this.appSettings.AdminApiPath + "Subsample/PanelOneRespondentsSelected/{panelId}",
    "digestSummary": this.appSettings.AdminApiPath + "panel-one/projects/digest-summary/{date}",
    "processing-items": this.appSettings.AdminApiPath + "processing-items"
  };

  getIncentive = (subsampleId: number): Observable<IIncentiveModel> =>
    this.http.getSubscription(this.urls["incentive"], { "subsampleId": subsampleId });

  getRespondentsSelected = (panelId: number): Observable<IRespondentsSelected[]> =>
    this.http.getSubscription(this.urls["respondentsSelected"], { "panelId": panelId });

  getDigestSummary = (date: string, countryId: number): Observable<IDigestSummary[]> =>
    this.http.getSubscription(this.urls["digestSummary"], {
      "date": date, "countryId": countryId
    });

  getProcessingItemsStatus = (countryId: number, subsampleIdList: number[], pageNo: number, perPage:number): Promise<any> =>
    this.http.post(this.urls["processing-items"], {
      "countryId": countryId,
      "pageNo": pageNo,
      "perPage": perPage
    }, subsampleIdList);
    
}
/*,
      "surveyIds": "{114712, 114843}"
      */
